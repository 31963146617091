<template>
  <Backhome></Backhome>
  <div class="relative bg-gray-100">
    <div class="lg:absolute lg:inset-0">
      <div class="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
        <img
          class="md:h-96 w-full object-cover lg:absolute lg:h-full"
          src="../assets/construction11.jpg"
          alt=""
        />
      </div>
    </div>
    <div
      class="relative pb-16 px-4 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2"
    >
      <div class="lg:col-start-2 lg:pl-8">
        <div
          class="text-base prose max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0"
        >
          <h3
            class="text-3xl mt-8 leading-8 font-Bold text-red-700 tracking-tight sm:text-4xl"
          >
            Civil and Structural Works
          </h3>
          <p class="mt-8 text-lg text-black">
            OILLEAD is committed to the principle of this age :
          </p>
          <ul role="list text-black">
            <li>
              Production of sound and cost-efficient architectural products and
              civil engineering services employing new ideas
            </li>
            <li>New techinques</li>
            <li>New dimension and new styles with high technical input</li>
            <li>Dredging Works</li>
            <li>Water treatment facilities</li>
            <li>Excavation and miscellaneous concrete works</li>
            <li>Construction of buildings and associated facilities</li>
            <li>Installation of fence and razor wire round cell site</li>
            <li>Casting of generator,shelter,and transformer plinths</li>
            <li>Erection of towers</li>
            <li>Piling and casting of equipment foundation</li>
            <li>Installation of chain link fence</li>
            <li>Drilling and casting of bored piles</li>
            <li>Rehabilitation of buildings</li>
            <li>Water projects(Borehole drilling and distribution)</li>
            <li>Drainage works</li>
            <li>Structural concrete works</li>
            <li>Road rehabilitation & construction works</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backhome from "../components/BackHome.vue";
export default {
  components: {
    Backhome,
  },
};
</script>
