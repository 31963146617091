<template>
  <div class="mx-auto bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 ourprojects">
    <div class="space-y-4 bg-gray-100">
      <h2
        class="text-3xl mt-3 bg-gray-100 text-center text-red-700 font-extrabold tracking-tight sm:text-4xl"
      >
        OUR PROJECTS
      </h2>

      <div class="row bg-gray-100">
        <div class="owl_wrapper bg-gray-100">
          <div id="owl-doctor" class="owl-carousel owl-theme">
            <swiper
              :pagination="{
                clickable: true,
              }"
              :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }"
              :loop="true"
              :navigation="true"
              :breakpoints="{
                '640': {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                '768': {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                '1024': {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }"
              :modules="modules"
              class="mySwiper"
            >
              <swiper-slide>
                <div class="owl-item">
                  <div class="item">
                    <div class="single-item">
                      <div class="doctor-img">
                        <a class="primary-img"
                          ><img
                            src="../assets/project9.jpeg"
                            class="img-responsive"
                            alt=""
                        /></a>
                      </div>
                      <div class="doctor-name">
                        <h4>
                          EPC for Gas Supply to BUA Cement Factory PreDesign,
                          FEED, Detailed Engineering, Procurement and
                          Construction of 16”x 29 Km gas Transmission pipeline
                          to BUA cement Factory. Includes Firewater Hydrant and
                          surpressant system. Storage tanks Fabrication and
                          Installation. etc
                        </h4>
                      </div>
                    </div>
                  </div>
                </div></swiper-slide
              ><swiper-slide>
                <div class="owl-item">
                  <div class="item">
                    <div class="single-item">
                      <div class="doctor-img">
                        <a class="primary-img"
                          ><img
                            src="../assets/project8.jpeg"
                            class="img-responsive"
                            alt=""
                        /></a>
                      </div>
                      <div class="doctor-name">
                        <h4>
                          EPC for Gas Supply to MBH Power/ Ikorodu Genco
                          Pre-Design, FEED, Detailed Engineering, Procurement
                          and Construction of 18”x 14 Km gas Transmission
                          pipeline and Firewater tanks for MBH power for Ikorodu
                          Genco Ltd
                        </h4>
                      </div>
                    </div>
                  </div>
                </div></swiper-slide
              >
              <swiper-slide>
                <div class="owl-item">
                  <div class="item">
                    <div class="single-item w-335">
                      <div class="doctor-img w-335">
                        <a class="primary-img"
                          ><img
                            src="../assets/project2.jpeg"
                            class="img-responsive"
                            alt=""
                        /></a>
                      </div>
                      <div class="doctor-name">
                        <h4>
                          Hot tapping procedure using split tee installation
                          method
                        </h4>
                      </div>
                    </div>
                  </div>
                </div></swiper-slide
              ><swiper-slide>
                <div class="owl-item">
                  <div class="item">
                    <div class="single-item w-335">
                      <div class="doctor-img w-335">
                        <a class="primary-img"
                          ><img
                            src="../assets/project12.jpeg"
                            class="img-responsive"
                            alt=""
                        /></a>
                      </div>
                      <div class="doctor-name">
                        <h4>Installation for BUA Sugar Refinery PH project.</h4>
                      </div>
                    </div>
                  </div>
                </div></swiper-slide
              ><swiper-slide>
                <div class="owl-item">
                  <div class="item">
                    <div class="single-item w-335">
                      <div class="doctor-img w-335">
                        <a class="primary-img"
                          ><img
                            src="../assets/project13.jpeg"
                            class="img-responsive"
                            alt=""
                        /></a>
                      </div>
                      <div class="doctor-name">
                        <h4>Installation for BUA Sugar Refinery PH.</h4>
                      </div>
                    </div>
                  </div>
                </div></swiper-slide
              ><swiper-slide>
                <div class="owl-item">
                  <div class="item">
                    <div class="single-item">
                      <div class="doctor-img">
                        <a class="primary-img"
                          ><img
                            src="../assets/project10.jpeg"
                            class="img-responsive"
                            alt=""
                        /></a>
                      </div>
                      <div class="doctor-name">
                        <h4>
                          EPC for Gas Supply to 6MW gas generator for NBC Abuja
                        </h4>
                      </div>
                    </div>
                  </div>
                </div></swiper-slide
              ><swiper-slide>
                <div class="owl-item">
                  <div class="item">
                    <div class="single-item">
                      <div class="doctor-img">
                        <a class="primary-img"
                          ><img
                            src="../assets/project11.jpeg"
                            class="img-responsive"
                            alt=""
                        /></a>
                      </div>
                      <div class="doctor-name">
                        <h4>
                          EPC for Gas Supply to 6MW gas generator for NBC Abuja
                        </h4>
                      </div>
                    </div>
                  </div>
                </div></swiper-slide
              ><swiper-slide
                ><div class="owl-item">
                  <div class="item">
                    <div class="single-item">
                      <div class="doctor-img">
                        <a class="primary-img"
                          ><img
                            src="../assets/project7.jpeg"
                            class="img-responsive"
                            alt=""
                        /></a>
                      </div>
                      <div class="doctor-name">
                        <h4>3Okm pipiline construction for BUA</h4>
                      </div>
                    </div>
                  </div>
                </div></swiper-slide
              >
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

export default {
  name: "MyComponent",
  components: { Swiper, SwiperSlide },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
};
</script>
