<template>
  <div class="relative bg-gray-100 pt-14 aboutus">
    <div class="lg:absolute lg:inset-0">
      <div class="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
        <img
          class="md:h-96 h-72 w-full object-cover lg:absolute lg:h-full"
          src="../assets/aboutus5.jpeg"
          alt=""
        />
      </div>
    </div>
    <div
      class="relative pb-16 px-4 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2"
    >
      <div class="lg:col-start-2 lg:pl-8">
        <div
          class="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0"
        >
          <h3
            class="text-3xl mt-4 leading-8 font-extrabold text-red-700 tracking-tight sm:text-4xl"
          >
            ABOUT US
          </h3>
          <p class="mt-8 text-black">
            Oillead Services is a multi-disciplinary servicing company involved
            in the Oil and Gas, and Energy/Power industries. We are a Nigerian
            company owned 100% by reputable Nigerians. We have extensive
            experience in oil, gas and power projects.
          </p>
          <div class="mt-5 prose prose-indigo text-black">
            <p>
              Our staffs of highly experienced professionals excel in areas of
              Engineering design, procurement, construction, commissioning andf
              project management. We manage all aspects of a project: project
              management, planning, design, material procurement, installation,
              commissioning and providing personnel. We are committed to
              continuously improving our performance and deliver best in class
              services (within budget and on schedule) to the complete
              satisfaction of our clients.
            </p>
            <p>
              We greatly emphasize on the health and safety of our employees and
              partners. Also, we are strongly committed to the sustainability of
              communities and the environment.
            </p>
            <h3 class="text-red-700">OUR VISION</h3>
            <p>
              To be the number one servicing company in Nigeria, with
              multinational capabilities and expertise in
            </p>
            <ul role="list">
              <li>Engineering Design</li>
              <li>Construction Services</li>
              <li>Project Management</li>
              <li>Material procurement within the Oil and Gas industry</li>
            </ul>
            <h3 class="text-red-700">OUR MISSION</h3>
            <p>
              To provide high quality services driven by research, innovation,
              responsiveness and commitment to customer satisfaction based on
              highly trained, competent and disciplined workforce using
              cutting-edge technology to deliver world class engineering
              services for our discerning clients.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
