<template>
  <Backhome></Backhome>
  <div class="relative bg-gray-100">
    <div class="lg:absolute lg:inset-0">
      <div class="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
        <img
          class="md:h-96 w-full object-cover lg:absolute lg:h-full"
          src="../assets/power1.jpg"
          alt=""
        />
      </div>
    </div>
    <div
      class="relative pb-16 px-4 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2"
    >
      <div class="lg:col-start-2 lg:pl-8">
        <div
          class="text-base prose max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0"
        >
          <h3
            class="text-3xl mt-8 leading-8 font-Bold text-red-700 tracking-tight sm:text-4xl"
          >
            Power Generation and Green Energy
          </h3>
          <p class="mt-8 text-lg text-black">
            OILLEAD has completed over the last 15 years various power
            installationsand distribution system; proffering solution to our
            client's power needa in a cost effective manner without compromising
            quality and safety. These services include:
          </p>
          <ul role="list" class="mt-5 text-black">
            <li>Load studies</li>
            <li>Procurement of power generating equiments</li>
            <li>Installation of power generators</li>
            <li>Operation and maintenance of generators</li>
            <li>Solar Energy systems</li>
            <li>Plant management</li>
            <li>Balance of plant</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backhome from "../components/BackHome.vue";
export default {
  components: {
    Backhome,
  },
};
</script>
