import { nextTick } from "vue";
import { createRouter, createWebHistory } from "vue-router";

import Home from "./components/HomePage.vue";
import DesignandEng from "./pages/DesignEng.vue";
import ProjectMagt from "./pages/ProjectMagt.vue";
import FireFighting from "./pages/FireFighting.vue";
import BasicEng from "./pages/BasicEng.vue";
import ConstructionDetails from "./pages/ConstructionDetails.vue";
import StructuralWorks from "./pages/StructuralWorks.vue";
import PowerGenerations from "./pages/PowerGenerations.vue";
import DetailedEng from "./pages/DetailedEng.vue";
import ElectricalWorks from "./pages/ElectricalWorks.vue";
import PipelineMagt from "./pages/PipelineMagt.vue";
import NotFound from "./components/NotFound.vue";
import OurClients from "./components/OurClients.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: { title: "Home - Oillead Services" },
    },
    {
      path: "/ourclients",
      name: "OurClients",
      component: OurClients,
      meta: { title: "Our Clients - Oillead Services" },
    },
    {
      path: "/designandengineering",
      name: "DesignandEng",
      component: DesignandEng,
      meta: { title: "Design and Engineering - Oillead Services" },
    },
    {
      path: "/projectmanagement",
      name: "ProjectMagt",
      component: ProjectMagt,
      meta: { title: "Project Management - Oillead Services" },
    },
    {
      path: "/fireandprotection",
      name: "FireFighting",
      component: FireFighting,
      meta: { title: "Fire Fighting and Protection System - Oillead Services" },
    },
    {
      path: "/basicengineering",
      name: "BasicEng",
      component: BasicEng,
      meta: { title: "Feed/Basic Engineering - Oillead Services" },
    },
    {
      path: "/constructiondetails",
      name: "ConstructionDetails",
      component: ConstructionDetails,
      meta: { title: "Fabrication and Construction - Oillead Services" },
    },
    {
      path: "/structuralworks",
      name: "StructuralWorks",
      component: StructuralWorks,
      meta: { title: "Civil and Structural Works - Oillead Services" },
    },
    {
      path: "/powergenerations",
      name: "PowerGenerations",
      component: PowerGenerations,
      meta: { title: "Power and Generations - Oillead Services" },
    },
    {
      path: "/detailedengineering",
      name: "DetailedEng",
      component: DetailedEng,
      meta: { title: "Detailed Engineering - Oillead Services" },
    },
    {
      path: "/electricalworks",
      name: "ElectricalWorks",
      component: ElectricalWorks,
      meta: { title: "Electrical Works - Oillead Services" },
    },
    {
      path: "/pipelinemanagement",
      name: "PipelineMagt",
      component: PipelineMagt,
      meta: {
        title: "Pipeline Integrity Management Services - Oillead Services",
      },
    },
    {
      path: "/:notFound(.*)",
      name: "NotFound",
      component: NotFound,
      meta: { title: "Not Found - Oillead Services" },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
});

const DEFAULT_TITLE = "Oillead Services";
router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
