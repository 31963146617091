<template>
  <div class="bg-gray-100 ourservices">
    <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8">
      <div class="space-y-12">
        <div class="space-y-5 sm:space-y-4">
          <h2
            class="text-3xl mt-4 text-center text-red-700 font-extrabold tracking-tight sm:text-4xl"
          >
            OUR SERVICES
          </h2>
          <p class="text-xl text-black">
            OILLEAD specializes in Oil and Gas, and Energy/Power industries
            including:
          </p>
        </div>
        <ul
          role="list"
          class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
        >
          <li v-for="person in people" :key="person.name">
            <div class="space-y-4" :class="person.link">
              <div class="aspect-w-3 aspect-h-2">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  :src="person.imageUrl"
                  alt=""
                />
              </div>

              <div class="space-y-2">
                <div class="text-lg leading-6 font-medium space-y-1">
                  <h3 class="font-bold uppercase">{{ person.name }}</h3>
                  <p class="text-black">
                    {{ person.role }}
                    <router-link
                      :to="servicepage(person.link)"
                      class="text-red-700 text-lg font-semibold hover:text-red-900"
                    >
                      read more
                    </router-link>
                  </p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import project1 from "../assets/designandengineering2.jpg";
import project2 from "../assets/projectmanagement.jpg";
import project3 from "../assets/detailedengineering.jpg";
import project4 from "../assets/firefighting1.jpeg";
import project5 from "../assets/pipeline.jpg";
import project6 from "../assets/electricalworks.jpg";
import project7 from "../assets/construction.jpg";
import project8 from "../assets/construction9.jpg";
import project9 from "../assets/power.jpg";
import project10 from "../assets/designandengineering4.jpg";
const people = [
  {
    name: "Design and Engineering",
    role: "OILLEAD provides complete engineering design services like conceptual design, feasibility studies, gas business studies for various project activities and processes",
    imageUrl: project1,
    link: "designandengineering",
  },
  {
    name: "Project Management",
    role: "The strength of our project management capabilities is the highly qualified and experienced people in our team to carry out project management to profitability",
    imageUrl: project2,
    link: "projectmanagement",
  },
  {
    name: "Detailed Engineering",
    role: "OILLEAD performs this role on many major projects. The involvement is determined by the type and scope of the project with the services provided frequently",
    imageUrl: project3,
    link: "detailedengineering",
  },
  {
    name: "Fire Fighting and Protection System",
    role: "Fire Fighting System. This includes FM 200 automatic system and C02 Automatic System with their smoke detection and alarm systems.It also includes External Fire Hydrants, Portable and Mobile Fire Extinguisher",
    imageUrl: project4,
    link: "fireandprotection",
  },
  {
    name: "Pipeline Integrity Management Services",
    role: "  OILLEAD maintains a database of seasoned professionals in all segments of the gas and liquid pipeline industry who are readily available for project execution.",
    imageUrl: project5,
    link: "pipelinemanagement",
  },
  {
    name: "Electrical Works",
    role: "OILLEAD has experienced personnel in electrical engineering to provide a complete electrical engineering solution for our clients.",
    imageUrl: project6,
    link: "electricalworks",
  },
  {
    name: "Civil and Structural Works",
    role: "OILLEAD is committed to the principle of this age: production of sound and cost-efficient architectural products and civil engineering services employing new ideas",
    imageUrl: project8,
    link: "structuralworks",
  },
  {
    name: "Power Generation and Green Energy",
    role: "OILLEAD has completed over the last 15 years various power installations and distribution system; proffering solution to our client's power needa in a cost effective manner",
    imageUrl: project9,
    link: "powergenerations",
  },
  {
    name: "Feed/Basic Engineering",
    role: "OILLEAD produces the basic engineering designs and followed these through to the preparation of tender documents and the evaluation of bids",
    imageUrl: project10,
    link: "basicengineering",
  },
  {
    name: "Fabrication,Construction and Installation",
    role: "OILLEAD offers a wide range of services which includes but not limited to the following",
    imageUrl: project7,
    link: "constructiondetails",
  },
];

export default {
  methods: {
    servicepage(data) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      return this.$route.path + data;
    },
  },
  setup() {
    return {
      people,
    };
  },
};
</script>
