<template>
  <main
    class="min-h-full html body bg-cover bg-top sm:bg-top bg-[url('../assets/404.jpeg')]"
  >
    <div
      class="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48"
    >
      <p
        class="text-sm font-semibold text-black text-opacity-50 uppercase tracking-wide"
      >
        404 error
      </p>
      <h1
        class="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl"
      >
        Uh oh! I think you’re lost.
      </h1>
      <p class="mt-2 text-lg font-semibold text-black text-opacity-50">
        It looks like the page you’re looking for doesn't exist.
      </p>
      <div class="mt-6">
        <router-link
          to="/"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-semibold rounded-md text-black text-opacity-75 bg-white"
        >
          Go back home
        </router-link>
      </div>
    </div>
  </main>
</template>

<style>
.html {
  height: 100vh !important;
}
.body {
  height: 100vh !important;
}
</style>
