<template>
  <Backhome></Backhome>
  <div class="relative bg-gray-100">
    <div class="lg:absolute lg:inset-0">
      <div class="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
        <img
          class="md:h-96 w-full object-cover lg:absolute lg:h-full"
          src="../assets/electricalworks1.jpg"
          alt=""
        />
      </div>
    </div>
    <div
      class="relative pb-16 px-4 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2"
    >
      <div class="lg:col-start-2 lg:pl-8">
        <div
          class="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0"
        >
          <h3
            class="text-3xl mt-8 leading-8 font-Bold text-red-700 tracking-tight sm:text-4xl"
          >
            Electrical Works
          </h3>
          <p class="mt-8 text-lg text-black">
            OILLEAD has experienced personnel in electrical engineering to
            provide a complete electrical engineering solution for our clients.
            We have friendly approachable staffs that provide clear information
            to the customer during project consultation. We are continually
            accessing new products that come on to the market, which gives our
            clients the solutions and systems that can best suit their needs.
          </p>
          <div class="mt-5 prose prose-indigo text-black">
            <p>
              Project engineering: Project management, Project design, Equipment
              schedules Control cabinet wiring schedules. OILLEAD has a core
              base of qualified industrial Engineers, electricians, and
              Technicians who can complete turnkey projects from workshop
              manufacturing, right through to site installation and
              commissioning. Our team can work on any size of project and
              provide a complete job to a high standard of workmanship.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backhome from "../components/BackHome.vue";
export default {
  components: {
    Backhome,
  },
};
</script>
