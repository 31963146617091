<template>
  <Popover class="relative bg-gray-300 sticky top-0 z-50">
    <div class="max-w-7xl mx-auto px-4 sm:px-6">
      <div
        class="flex justify-between items-center py-2 lg:justify-between md:space-x-10"
      >
        <div>
          <router-link
            to="/"
            class="flex items-center justify-center text-center"
          >
            <span class="sr-only text-bluecolor">oillead</span>
            <img class="h-8 w-auto sm:h-10" src="../assets/logo1.png" alt="" />

            <div
              class="relative flex items-start items-center justify-center text-center"
            >
              <div class="ml-2 text-sm">
                <div
                  class="text-base font-bold lineheight text-bluecolor2 cursor-pointer items-center justify-center text-center"
                >
                  OIL<span class="text-red-700">L</span>EAD
                </div>
                <div
                  class="text-base font-bold lineheight text-red-700 cursor-pointer items-center justify-center text-center"
                >
                  SERVICES
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="-mr-2 -my-2 lg:hidden">
          <PopoverButton
            class="bg-gray-100 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
          >
            <span class="sr-only text-bluecolor">Open menu</span>
            <MenuIcon class="h-6 w-6" aria-hidden="true" />
          </PopoverButton>
        </div>
        <PopoverGroup as="nav" class="hidden lg:flex space-x-10">
          <a
            @click="scrollMeTo('.ourhome')"
            class="text-base font-bold text-bluecolor2 cursor-pointer"
          >
            HOME
          </a>
          <a
            @click="scrollMeTo('.ourservices')"
            class="text-base font-bold text-bluecolor2 cursor-pointer"
          >
            SERVICES
          </a>
          <a
            @click="scrollMeTo('.aboutus')"
            class="text-base font-bold text-bluecolor2 cursor-pointer"
          >
            ABOUT US
          </a>
          <a
            @click="scrollMeTo('.ourprojects')"
            class="text-base font-bold text-bluecolor2 cursor-pointer"
          >
            PROJECTS </a
          ><a
            @click="scrollMeTo('ourclients')"
            class="text-base font-bold text-bluecolor2 cursor-pointer"
          >
            OUR CLIENTS
          </a>

          <a
            @click="scrollMeTo('.ourcontact')"
            class="text-base font-bold text-bluecolor2 cursor-pointer"
          >
            CONTACT US
          </a>
        </PopoverGroup>
      </div>
    </div>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <PopoverPanel
        focus
        class="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
      >
        <div class="rounded-lg shadow-lg bg-gray-300 divide-y-2 divide-gray-50">
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <div>
                <PopoverButton
                  class="flex focus:outline-none items-center justify-center text-center"
                >
                  <span class="sr-only text-bluecolor">oillead</span>
                  <img
                    class="h-8 w-auto sm:h-10"
                    src="../assets/logo1.png"
                    alt=""
                  />

                  <div
                    class="relative flex items-start items-center justify-center text-center"
                  >
                    <div class="ml-2 text-sm">
                      <div
                        class="text-base font-bold lineheight text-bluecolor2 cursor-pointer items-center justify-center text-center"
                      >
                        OIL<span class="text-red-700">L</span>EAD
                      </div>
                      <div
                        class="text-base font-bold lineheight text-red-700 cursor-pointer items-center justify-center text-center"
                      >
                        SERVICES
                      </div>
                    </div>
                  </div>
                </PopoverButton>
              </div>

              <div class="-mr-2">
                <PopoverButton
                  class="bg-gray-100 rounded-md p-2 inline-flex items-center justify-center text-gray-400 focus:outline-none"
                >
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="mt-10">
              <nav class="grid gap-y-8">
                <PopoverButton
                  v-for="item in solutions"
                  :key="item.name"
                  @click="scrollMeTo(item.link)"
                  class="-m-3 p-3 flex items-center rounded-md focus:bg-gray-50 hover:bg-gray-50"
                >
                  <img
                    class="flex-shrink-0 h-6 w-6 text-indigo-600"
                    :src="item.imageUrl"
                    alt=""
                  />
                  <div class="ml-3 text-base font-medium text-gray-900">
                    {{ item.name }}
                  </div>
                </PopoverButton>
              </nav>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>
import home from "../assets/icons8-home-page-30.png";
import services from "../assets/icons8-services-30.png";
import projects from "../assets/icons8-project-management-30.png";
import about from "../assets/icons8-about-30.png";
import contact from "../assets/icons8-contact-us-30.png";
import ourclients from "../assets/icons8-people-30.png";

import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";

const solutions = [
  {
    name: "HOME",
    description:
      "OILLEAD provides complete engineering design services like conceptual design, feasibility studies, gas business studies for various project activities and processes",
    link: ".ourhome",
    imageUrl: home,
  },
  {
    name: "SERVICES",
    description:
      "OILLEAD maintains a database of seasoned professionals in all segments of the gas and liquid pipeline industry who are readily available for project execution.",
    link: ".ourservices",
    imageUrl: services,
  },
  {
    name: "PROJECTS",
    description:
      "OILLEAD has experienced personnel in electrical engineering to provide a complete electrical engineering solution for our clients.",
    link: ".ourprojects",
    imageUrl: projects,
  },
  {
    name: "ABOUT US",
    description:
      "The strength of our project management capabilities is the highly qualified and experienced people in our team to carry out project management to profitability",
    link: ".aboutus",
    imageUrl: about,
  },
  {
    name: "OUR CLIENTS",
    description:
      "OILLEAD has experienced personnel in electrical engineering to provide a complete electrical engineering solution for our clients.",
    link: "ourclients",
    imageUrl: ourclients,
  },
  {
    name: "CONTACT US",
    description:
      "The strength of our project management capabilities is the highly qualified and experienced people in our team to carry out project management to profitability",
    link: ".ourcontact",
    imageUrl: contact,
  },
];

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    MenuIcon,
    XIcon,
  },
  methods: {
    scrollMeTo(data) {
      if (data == "ourclients") {
        return this.$router.push("/ourclients");
      }
      var element = document.querySelector(data);
      var top = element.offsetTop;
      window.scrollTo({ top: top, left: 0, behavior: "smooth" });
    },
  },
  setup() {
    return {
      solutions,
    };
  },
};
</script>
