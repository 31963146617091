<template>
  <Info></Info>
  <Header></Header>
  <Homesection></Homesection>
  <Markets></Markets>
  <About></About>
  <Projects></Projects>
  <Contact></Contact>
  <Footer></Footer>
</template>

<script>
import Info from "./CompanyInfo.vue";
import Header from "./NavBar.vue";
import Homesection from "./HomeSection.vue";
import About from "./AboutUs.vue";
import Markets from "./OurServices.vue";
import Contact from "./ContactUs.vue";
import Projects from "./OurProjects.vue";
import Footer from "./FooterDetails.vue";

export default {
  components: {
    Header,
    Homesection,
    About,
    Markets,
    Contact,
    Projects,
    Info,
    Footer,
  },
};
</script>
