<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Backhome></Backhome>
  <div class="bg-gray-100">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <p
        class="text-center text-3xl font-semibold uppercase text-gray-600 tracking-wider"
      >
        OUR CLIENTS
      </p>
      <div class="mt-6 grid grid-cols-1 gap-0.5 md:grid-cols-2 lg:mt-8">
        <div class="col-span-1 flex items-center py-8 px-8 bg-white">
          <img class="max-h-24" src="../assets/bua.png" alt="Workcation" />
          <h2 class="ml-5 font-bold text-2xl text-gray-600">BUA GROUP</h2>
        </div>
        <div class="col-span-1 flex items-center py-8 px-8 bg-white">
          <img class="max-h-24" src="../assets/ngc1.jpeg" alt="Mirage" />
          <h2 class="ml-5 font-bold text-2xl text-gray-600">
            NIGERIAN GAS COMPANY
          </h2>
        </div>
        <div class="col-span-1 flex items-center py-8 px-8 bg-white">
          <img
            class="max-h-24 maxwidth"
            src="../assets/quest1.png"
            alt="Tuple"
          />
          <h2 class="ml-5 font-bold text-2xl text-gray-600">QUEST OIL GROUP</h2>
        </div>
        <div class="col-span-1 flex items-center py-8 px-8 bg-white">
          <img class="max-h-24" src="../assets/olam.png" alt="Laravel" />
          <h2 class="ml-5 font-bold text-2xl text-gray-600">OLAM GROUP</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backhome from "../components/BackHome.vue";
export default {
  components: {
    Backhome,
  },
};
</script>
