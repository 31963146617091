<template>
  <Backhome></Backhome>
  <div class="relative bg-gray-100">
    <div class="lg:absolute lg:inset-0">
      <div class="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
        <img
          class="md:h-96 w-full object-cover lg:absolute lg:h-full"
          src="../assets/construction1111.jpg"
          alt=""
        />
      </div>
    </div>
    <div
      class="relative pb-16 px-4 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2"
    >
      <div class="lg:col-start-2 lg:pl-8">
        <div
          class="text-base prose max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0"
        >
          <h3
            class="text-3xl mt-8 leading-8 font-Bold text-red-700 tracking-tight sm:text-4xl"
          >
            Fabrication,Construction and Installation
          </h3>
          <p class="mt-8 text-lg text-black">
            OILLEAD offers a wide range of services which includes but not
            limited to the following
          </p>

          <ul role="list" class="text-black">
            <li>Mechanical</li>
            <li>Civil</li>
            <li>Structural</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backhome from "../components/BackHome.vue";
export default {
  components: {
    Backhome,
  },
};
</script>
