<template>
  <Backhome></Backhome>
  <div class="relative bg-gray-100">
    <div class="lg:absolute lg:inset-0">
      <div class="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
        <img
          class="md:h-96 h-60 w-full object-cover lg:absolute lg:h-full"
          src="../assets/detailedengineering1.jpg"
          alt=""
        />
      </div>
    </div>
    <div
      class="relative pb-16 px-4 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2"
    >
      <div class="lg:col-start-2 lg:pl-8">
        <div
          class="text-base prose max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0"
        >
          <h3
            class="text-3xl mt-8 leading-8 font-Bold text-red-700 tracking-tight sm:text-4xl"
          >
            Detailed Engineering
          </h3>
          <p class="mt-8 text-lg text-black">
            OILLEAD performs this role on many major projects. The involvement
            is determined by the type and scope of the project with the services
            provided frequently including, but not limited to:
          </p>
          <ul role="list" class="text-black">
            <li>Process design and equipment specification</li>
            <li>Pipeline routing and Construction details</li>
            <li>Pipeline hydraulic and Surge analysis</li>
            <li>Rotating machinery and auxiliary systems</li>
            <li>Detailed piping design through to isometrics</li>
            <li>
              Corrosion prevention, internal/external, above/below ground.
            </li>
            <li>Electrical design through to cabling details</li>
            <li>Instrumentation design through to wiring details</li>
            <li>Structural design and foundation details</li>
            <li>Telecommunication system design.</li>
            <li>Control philosophy and SCADA design</li>
            <li>Preparation of requisitions and materials take-offs.</li>
            <li>Manpower Supply</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backhome from "../components/BackHome.vue";
export default {
  components: {
    Backhome,
  },
};
</script>
