<template>
  <div id="ourcontact" class="relative bg-gray-800 company">
    <div
      class="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2"
    >
      <img
        class="w-full h-full object-cover"
        src="../assets/assistance11.jpg"
        alt=""
      />
    </div>
    <div class="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
      <div class="md:ml-auto md:w-1/2 md:pl-10">
        <p
          class="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl"
        >
          We’re here to help
        </p>
        <p class="mt-3 text-lg text-gray-300">
          We support clients in the oil & gas, energy, government, and
          manufacturing sectors with Technical advice, Regulatory framework
          reviews and Strategic assessments (SEA)
        </p>
        <div class="mt-8">
          <div class="inline-flex rounded-md shadow">
            <a
              @click="scrollMeTo('.ourcontact')"
              class="inline-block rounded-lg px-4 py-1.5 cursor-pointer bg-white text-base font-semibold leading-7 text-black ring-1 ring-gray-900/10 hover:bg-white"
            >
              CONTACT US
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  methods: {
    scrollMeTo(data) {
      console.log(data);
      var element = document.querySelector(data);
      console.log(element);
      var top = element.offsetTop;
      console.log(top);
      window.scrollTo({ top: top, left: 0, behavior: "smooth" });
    },
  },
  setup() {
    return {};
  },
};
</script>
