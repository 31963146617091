<template>
  <Backhome></Backhome>
  <div class="relative bg-gray-100">
    <div class="lg:absolute lg:inset-0">
      <div class="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
        <img
          class="md:h-96 w-full object-cover lg:absolute lg:h-full"
          src="../assets/designandengineering44.jpg"
          alt=""
        />
      </div>
    </div>
    <div
      class="relative pb-16 px-4 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2"
    >
      <div class="lg:col-start-2 lg:pl-8">
        <div
          class="text-base prose max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0"
        >
          <h3
            class="text-3xl mt-8 leading-8 font-Bold text-red-700 tracking-tight sm:text-4xl"
          >
            Feed/Basic Engineering
          </h3>
          <p class="mt-8 text-lg text-black">
            OILLEAD produces the basic engineering designs and followed these
            through to the preparation of tender documents and the evaluation of
            bids for many projects. The scope of OILLEAD ’s work typically
            includes:
          </p>
          <ul role="list" class="text-black">
            <li>Overall system and project definition and specification</li>
            <li>Survey</li>
            <li>Process design philosophy</li>
            <li>
              Technical dossier (specifications, calculation notes, general
              drawings)
            </li>
            <li>Evaluation of tenders.</li>
            <li>Manpower Supply</li>
            <li>
              Incorporated in the call to tender for turnkey contracts and long
              lead items supply.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backhome from "../components/BackHome.vue";
export default {
  components: {
    Backhome,
  },
};
</script>
