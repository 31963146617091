<template>
  <Backhome></Backhome>
  <div class="relative bg-gray-100">
    <div class="lg:absolute lg:inset-0">
      <div class="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
        <img
          class="md:h-96 w-full object-cover lg:absolute lg:h-full"
          src="../assets/pipeline1.jpg"
          alt=""
        />
      </div>
    </div>
    <div
      class="relative pb-16 px-4 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2"
    >
      <div class="lg:col-start-2 lg:pl-8">
        <div
          class="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0"
        >
          <h3
            class="text-3xl mt-8 leading-8 font-Bold text-red-700 tracking-tight sm:text-4xl"
          >
            Pipeline Integrity Management Services
          </h3>
          <p class="mt-8 text-lg text-black">
            OILLEAD maintains a database of seasoned professionals in all
            segments of the gas and liquid pipeline industry who are readily
            available for project execution. OILLEAD pipeline professionals
            bring adequate years of engineering, construction, operations,
            rehabilitation and risk assessment experience to any project.
          </p>
          <div class="mt-5 prose prose-indigo text-black">
            <p>
              OILLEAD offers a range of integrated services applicable to new
              and existing pipelines and pipeline system. A full range of
              pipeline services are offered including
            </p>
            <ul role="list">
              <li>Cleaning</li>
              <li>Hot tapping and Cold tapping</li>
              <li>Thrust boring</li>
              <li>Gas leak detection</li>
              <li>Helium leak detection</li>
              <li>Nitrogen related services</li>
              <li>Hydrostatic and pneumatic testing</li>
              <li>Drying</li>
              <li>Inspection</li>
              <li>Pigging</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backhome from "../components/BackHome.vue";
export default {
  components: {
    Backhome,
  },
};
</script>
