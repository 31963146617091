<template>
  <div>
    <div class="border-b-2 bg-gray-300 hidden md:flex">
      <div class="max-w-7xl mx-auto px-2 sm:px-12">
        <div
          class="flex justify-between items-center border-gray-100 py-2 md:justify-start md:space-x-10"
        >
          <div class="flex">
            <div class="flex-shrink-0">
              <MailIcon class="h-6 w-6 text-black" aria-hidden="true" />
            </div>
            <div class="ml-3 text-base text-black">
              <a href="mailto:oilleadserv@gmail.com"
                >info@oilleadservices.com.ng</a
              >
            </div>
          </div>
          <div class="flex">
            <div class="flex-shrink-0">
              <PhoneIcon class="h-6 w-6 text-black" aria-hidden="true" />
            </div>
            <div class="ml-3 text-base text-black">
              <a href="tel:+2348033735826">+2348033735826</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      data-mdb-ripple="true"
      data-mdb-ripple-color="light"
      @click="backToTop"
      class="inline-block z-10 p-3 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out bottom-5 right-5"
      id="btn-back-to-top"
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        class="w-4 h-4"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path
          fill="currentColor"
          d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script>
import { MailIcon, PhoneIcon } from "@heroicons/vue/outline";
window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  console.log("scrolled");
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    let mybutton = document.getElementById("btn-back-to-top");
    mybutton.style.display = "block";
  } else {
    let mybutton = document.getElementById("btn-back-to-top");
    mybutton.style.display = "none";
  }
}

export default {
  methods: {
    backToTop() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
  },
  components: {
    MailIcon,
    PhoneIcon,
  },
};
</script>
